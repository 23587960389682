import React, { useRef, useState } from "react"
import * as styles from './karte.module.scss';

import Layout from "../page-components/layout/layout"
import { graphql, Link } from "gatsby"
import GoogleMapReact from "google-map-react"
import useSupercluster from "use-supercluster"

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";

import HomeSVG from "../../icons/fontawesome/light/house.svg";
import { GatsbyImage } from "gatsby-plugin-image"

import TimesSVG from "../../icons/fontawesome/light/times.svg";

const Marker = ({ children }) => children;

const KartePage = ( { data } ) => {

  const mapRef = useRef();
  const [ showElement, setShowElement ] = useState(null);

  const points = data.allWpRecord.nodes
    .filter(a => !!a.acf.place)
    .map(
    wp => ({
      type: "Feature",
      properties: {
        title: wp.title,
        slug: wp.slug,
        id: wp.id
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(wp.acf.place.longitude),
          parseFloat(wp.acf.place.latitude)
        ]
      }
    })
  );

  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(9);

  const { clusters, supercluster } = useSupercluster(
    {
      points,
      bounds,
      zoom,
      options: {
        radius: 75,
        maxZoom: 13
      }
    }
  );

  return <Layout>


    <div className="row">
      <div className="col-12">
        <div className="py-4 bg-primary-dark text-white text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Karte</h1>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="row">
      <div className="col-12">

        <div style={{ height: '80vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCAWHzz1bsGomrv6H6Qj8FitTu7hvJ1Cvg" /* YOUR KEY HERE */ }}
            defaultCenter={
              {
                lat: 48.18,
                lng: 14.38
              }
            }
            defaultZoom={zoom}
            onChange={
              ({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([ bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat ]);
              }
            }
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
          >

            { clusters.map(cluster => {

              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount
              } = cluster.properties;



              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.properties.cluster_id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <div
                      className={styles.crimeMarker}
                      style={{
                        width: `${40 + (pointCount / points.length) * 20}px`,
                        height: `${40 + (pointCount / points.length) * 20}px`
                      }}
                      onClick={() => {
                        const expansionZoom = Math.max(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          15
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }

              const node = data.allWpRecord.nodes.find(
                n => n.id === cluster.properties.id
              );

              return (
                <Marker
                  key={`crime-${cluster.properties.id}`}
                  lat={latitude}
                  lng={longitude}
                >

                  <div className={styles.clusterMarker} >
                    <div
                      style={{
                        width: `${20 + (pointCount / points.length) * 10}px`,
                        height: `${20 + (pointCount / points.length) * 10}px`
                      }}
                      onClick={() => { setShowElement(cluster.properties.id) }}
                    >
                      <HomeSVG className={"svgFill white "} />

                      {showElement === cluster.properties.id &&
                        <div className={styles.overlay}>

                          <div className="d-flex justify-content-between align-items-center">

                            <p>
                            { cluster.properties.title }
                            </p>

                            <TimesSVG
                              className={"c-pointer"}
                              onClick={
                                () => {
                                  window.setTimeout(
                                    () => {
                                      setShowElement(null);
                                    },
                                    0
                                  )

                                }
                            }
                            />


                          </div>

                          {node.acf.teaser?.localFile &&
                            <GatsbyImage alt={"Bild von " + cluster.properties.title}
                                         className={"mb-3 mt-1"}
                                         image={node.acf.teaser?.localFile?.childImageSharp?.gatsbyImageData} />
                          }

                          <Link to={"/aufnahme/" + cluster.properties.slug}>
                            <div className="d-flex align-items-center">
                              <ChevronRightSVG className={"me-1 svgFill"} />
                              zum 3D-Rundgang
                            </div>
                          </Link>

                        </div>
                      }

                    </div>
                  </div>


                </Marker>
              );

            })
            }


          </GoogleMapReact>
        </div>

      </div>
    </div>



  </Layout>
};

export default KartePage;

export const query = graphql`
query KartePage {  
  allWpRecord {
      nodes {
        databaseId
        id
        slug
        title
        acf {
          stadt {
            ... on WpStadt {
              id
              title
            }
          }
          place {
            latitude
            longitude
          }
          teaser {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
          }
      }
  }
  }
}`;
